import { FeedRow } from '@@types/Api';
import httpClient from '@@utils/HttpClient';
import { PROGRAM_LOOKUP_PATH, PROGRAM_LOOKUP_BY_ID_PATH } from '@@utils/constants';

import { FeedItem } from './FeedApi';

export interface ProgramApiResponse {
  program: FeedItem.TvSeries;
  featuredVideo?: {
    title: string;
    video: FeedItem.Video;
  };
  seasons?: {
    name: string;
    metadata?: {
      seasonNumber?: number;
    };
    feedUrl: string;
  }[];
  rows?: FeedRow[];
}

export function fetchBySlug(slug: string): Promise<ProgramApiResponse> {
  const url = PROGRAM_LOOKUP_PATH.replace('[SLUG]', slug);

  return httpClient.get<ProgramApiResponse>(url).then((response) => {
    const { data } = response;
    return data;
  });
}

export function fetchById(id: string): Promise<ProgramApiResponse> {
  const url = PROGRAM_LOOKUP_BY_ID_PATH.replace('[ID]', id);

  return httpClient.get<ProgramApiResponse>(url).then((response) => {
    const { data } = response;
    return data;
  });
}

export default {
  fetchBySlug,
  fetchById,
};
