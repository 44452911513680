import { AppBar } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent, useCallback, useState, useEffect } from 'react';
import * as React from 'react';

import OnDemand from '@@types/OnDemand';

import { generatePathFromLinkProps } from '../../routes';
import OdShortcutBar from '../Buttons/OdShortcutBar';
import OdContainer from '../Utils/OdContainer';
import EpisodeList from './EpisodeList';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      marginTop: '0 !important',
      flexGrow: 1,
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
    menu: {
      background: 'none',
      boxShadow: 'none',
      zIndex: 1,
    },
    content: {
      paddingTop: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        paddingTop: 0,
      },
    },
  });
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
}

const TabPanel = (tabPanelProps: TabPanelProps) => {
  const {
    children,
    index,
    ...other
  } = tabPanelProps;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div role="tabpanel" {...other}>{children}</div>
  );
};

export interface SeasonTabsProps {
  seriesSlug: string;
  seriesType: OnDemand.SeriesType;
  selectedTabIndex?: number;
  items: OnDemand.SeriesItemSeason[];
  onChange?(newIndex: number): void;
  renderTabAsLink?: boolean;
}

const SeasonTabbedView: FunctionComponent<SeasonTabsProps> = (props) => {
  const classes = useStyles({});
  const {
    selectedTabIndex,
    items,
    seriesSlug,
    seriesType,
    renderTabAsLink = true,
  } = props;
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const handleChange = useCallback((_event: React.ChangeEvent, newIndex: number) => {
    if (selectedTabIndex === undefined) {
      setCurrentTabIndex(newIndex);
    }
  }, [selectedTabIndex]);

  useEffect(() => {
    if (selectedTabIndex !== undefined) {
      setCurrentTabIndex(selectedTabIndex);
    }
  }, [selectedTabIndex]);

  let links;
  let labels;

  // On v3, we render tab label
  // With Catalog API TV Series we render a link to the season url for each tab
  if (renderTabAsLink) {
    links = items.map((item) => {
      return {
        uri: generatePathFromLinkProps({
          name: 'series',
          params: {
            slug: seriesSlug,
            seasonSlug: item.seasonSlug,
            seriesType,
          },
        }),
        label: item.name,
      };
    });
  } else {
    labels = items.map((item) => {
      return item.name;
    });
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.menu}>
        <OdShortcutBar
          defaultValue={currentTabIndex}
          onChange={handleChange}
          title="Season tabs"
          labels={labels}
          links={links}
        />
      </AppBar>

      <OdContainer className={classes.content}>
        {
          items.map((item, index) => {
            if (index !== currentTabIndex) return null;

            return (
              <TabPanel key={item.id} index={index}>
                <EpisodeList items={item.episodes}/>
              </TabPanel>
            );
          })
        }
      </OdContainer>
    </div>
  );
};

export default SeasonTabbedView;
