import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';

import OnDemand from '../../@types/OnDemand';
import { getImageByType } from '../../utils/ApiV3ItemUtils';
import Image from '../Html/Image';
import VideoPlayWidget from './VideoPlayWidget';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    thumbnail: {
      width: 280,
      marginRight: 23,
      '& img': {
        width: '100%',
        display: 'block',
      },
      [theme.breakpoints.down('sm')]: {
        width: 385,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginRight: 0,
        marginBottom: 16,
      },
      '& + div': {
        flex: 1,
      },
    },
    description: {
      fontSize: '1.125rem',
      lineHeight: 1.56,
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
    playWidget: {
      marginBottom: 5,
    },
  });
});

export interface VideoFeaturedProps {
  video: OnDemand.SlimVideo;
  label?: string;
  classes?: {
    root?: any;
  };
}

const FeaturedVideo: FunctionComponent<VideoFeaturedProps> = (props) => {
  const {
    video, label = null,
  } = props;
  const classes = useStyles(props);

  let imageSrc;
  let imageId;

  if ('odImageId' in video) {
    imageId = video.odImageId;
  } else {
    const { images } = video;
    imageSrc = getImageByType(images, ['hd720', 'thumbnailLarge', 'thumbnail']);
  }

  return (
    <div className={classes.root}>
      <div className={classes.thumbnail}>
        <Image
          alt={video.title}
          src={imageSrc}
          imageId={imageId}
          srcSetWidths={[770, 1440]}
          ratio="57:32"
          imageSizes={{
            xs: 720,
            all: 385,
          }}
        />
      </div>
      <div>
        <VideoPlayWidget
          video={video}
          label={label}
          classes={{ root: classes.playWidget }}
        />
        <div className={classes.description}>
          {video.shortDescription}
        </div>
      </div>
    </div>
  );
};

export default FeaturedVideo;
