import { Hidden, Typography, Tooltip } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { get } from 'lodash';
import { Duration } from 'luxon';
import { useState, FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAppSelector } from '@@src/hooks/store';
import { selectProgress } from '@@stores/UserActivityStore';
import { getImageByType } from '@@utils/ApiV3ItemUtils';
import { onButtonKeyListener } from '@@utils/helpers';

import OnDemand from '../../@types/OnDemand';
import { durationShortFormatParams } from '../../i18n';
import { ReactComponent as ExpandIcon } from '../../images/icons/expand.svg';
import { generatePathFromLinkProps, OdLink } from '../../routes';
import grey from '../../styles/colors/grey';
import orange from '../../styles/colors/orange';
import fontFamily from '../../styles/typography/fontFamily';
import Image from '../Html/Image';
import VideoProgressBar from '../ProgressBar/VideoProgressBar';
import ClassificationBadge from '../Utils/ClassificationBadge';
import ExpirationDate from '../Utils/ExpirationDate';
import PlayButton from '../Video/PlayButton';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      fontSize: '1rem',
      display: 'flex',
      backgroundColor: grey.blackpearl,
      position: 'relative',
      '& > div:nth-child(1)': { // first column
        position: 'relative',
        flex: '0 0',
      },
      '& > div:nth-child(2)': { // second column
        padding: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
          padding: `11px ${theme.spacing(7)}px ${theme.spacing(1)}px 0`,
        },
        flex: 1,
      },
      borderRadius: 4,
      overflow: 'hidden',
    },
    column: {
      height: 220,
      [theme.breakpoints.down('md')]: {
        height: 193,
      },
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
      '& $thumbnail': {
        display: 'block',
        height: '100%',
        width: 'auto',
      },
    },
    thumbnail: {
    },
    summaryContainer: {
      display: 'flex',
      flexDirection: 'column',
      '& > *:nth-child(n+2)': {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(1),
        },
      },
    },
    title: {
      fontFamily: fontFamily.ubuntu,
      fontWeight: 'bold',
      fontSize: '1.25em',
      [theme.breakpoints.down('md')]: {
        lineHeight: 1.2,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'calc(100% - 40px)',
        fontSize: '1em',
      },
    },
    description: {
      fontSize: '1.125em',
      lineHeight: 1.5556,
      color: darken(theme.palette.primary.main, 0.12),
      flexGrow: 1,
      [theme.breakpoints.up('md')]: {
        maxHeight: '6em',
      },
      overflow: 'hidden',
      maxWidth: 1300,
      [theme.breakpoints.down('md')]: {
        fontSize: '1em',
        lineHeight: 1.5,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875em',
        display: 'none',
      },
      '& $metadata': {
        marginTop: 8,
      },
      '& $consumerAdvices': {
        fontSize: '0.75rem',
      },
    },
    metadata: {
      fontSize: '1em',
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          marginRight: theme.spacing(1),
        },
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75em',
      },
    },
    duration: {
      lineHeight: 1.5, // to compensate smaller font size so that it's bottom aligned with the siblings
      alignSelf: 'center',
      transform: 'translateY(1px)', // to align with the classification badge
    },
    availability: {
      fontFamily: fontFamily.secondary,
      color: orange.darkTangerine,
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        fontWeight: 500,
      },
      transform: 'translateY(1px)', // to align with the classification badge
    },
    classification: {
      fontSize: '1em',
      lineHeight: 1,
      fontWeight: 'bold',
      padding: '3px 9px 2px',
      color: grey.jaguar,
      borderRadius: 2,
      backgroundColor: grey.chateau,
      alignSelf: 'center',
    },
    consumerAdvices: {
      fontSize: '1rem',
      transform: 'translateY(1px)', // to align with the classification badge
      lineHeight: 1,
      color: 'white',
      opacity: 0.5,
      alignSelf: 'center',
    },
    playLink: {
      display: 'flex',
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
      // removes the broken outline when focusing on episode thumbnail
      // play button still appears when episode thumbnail is focused
      '&:focus': {
        outline: 'none',
      },
    },
    expandButton: {
      cursor: 'pointer',
      userSelect: 'none',
    },
    expandToggleButton: {
      position: 'absolute',
      padding: theme.spacing(1.5),
      top: 8,
      right: 0,
    },
    expandIcon: {
      fontSize: '1.5em',
      opacity: 0.5,
      fill: 'white',
      display: 'block',
    },
    expanded: {
      transform: 'rotate(180deg)',
      opacity: 1,
    },
    playButton: {
      '$root:hover &': {
        opacity: 0.8,
      },
      '$root:hover &:hover': {
        opacity: 0.9,
      },
    },
    playButtonOverlay: {
      '$root:hover &': {
        opacity: 1,
      },
    },
    tooltip: {
      backgroundColor: grey.white,
      color: grey.black,
      fontSize: '1rem',
      maxWidth: 'none',
      transition: 'none !important',
    },
    tooltipArrow: {
      color: grey.white,
      fontSize: '0.5rem',
      marginBottom: '-5px !important',
    },
  });
});

type Props = {
  slimEpisode: OnDemand.SlimEpisode
};

const EpisodeRow: FunctionComponent<Props> = ({ slimEpisode }) => {
  const {
    id, // mpxMediaId
    catalogueId,
    title,
    shortDescription: description,
    duration: durationSeconds = 0,
    classification,
    consumerAdvices,
    consumerAdviceTexts,
    expiredDate,
    episodeData,
    route,
    images,
    odImageId,
  } = slimEpisode;

  const classes = useStyles({});
  const { t } = useTranslation('common');
  const history = useHistory();

  const progressMap = useAppSelector(selectProgress);
  const progressData = progressMap[catalogueId] ?? {
    percent: 0,
    seconds: 0,
    updatedAt: '',
  };

  const durationFormatUnits = ['d', 'h', 'm'];
  let duration = Duration.fromObject({ hours: 0, minutes: 0, seconds: durationSeconds });
  if (durationSeconds >= 60) {
    // get duration iso to the nearest hours / minute
    // +30 seconds for rounding because the seconds will be dropped
    duration = duration.plus({ seconds: 30 }).normalize().set({ seconds: 0 });
  } else {
    durationFormatUnits.push('s');
    duration.normalize();
  }

  // state to show description on mobile
  const [showDescription, setShowDescription] = useState<boolean>(false);

  const toggleDescription = () => {
    setShowDescription((state) => {
      return !state;
    });
  };

  const playVideoHandler = (e) => {
    e.preventDefault();

    history.push(generatePathFromLinkProps({
      name: 'watch',
      params: {
        id,
      },
    }));
  };

  const episodeNumber = get(episodeData, 'episodeNumber');
  const seasonNumber = get(episodeData, 'seasonNumber');
  const programName = get(episodeData, 'programName');
  const episodeTitle = (epTitle) => {
    if (epTitle === (`Episode ${episodeNumber}`)) {
      return '';
    } return `${title} `;
  };

  let progress = 0;

  if (progressData) {
    progress = progressData.percent === 100 ? 0 : progressData.seconds;
  }

  let label;
  if (progress > 0) {
    label = t('playback.resumeEpisode', {
      title: programName,
      season: seasonNumber,
      episode: episodeNumber,
      time: progress,
      episodeTitle: episodeTitle(title),
    });
  } else {
    label = t('playback.playEpisode', {
      title: programName,
      season: seasonNumber,
      episode: episodeNumber,
      episodeTitle: episodeTitle(title),
    }).trim();
  }

  let consumerAdvicesString = '';

  if (
    consumerAdvices
    && Object.keys(consumerAdvices).length > 0
  ) {
    consumerAdvicesString += 'Advice: ';
    consumerAdvicesString += consumerAdvices.map((advice) => {
      return advice.toUpperCase();
    }).join(', ');
  }

  const SummaryContainer = useCallback<FunctionComponent>(({ children }) => {
    return (
      <div className={`${classes.summaryContainer} ${classes.column}`}>
        <Hidden mdUp>
          <div
            role="button"
            tabIndex={0}
            onClick={toggleDescription}
            onKeyPress={toggleDescription}
            className={clsx('noselect', classes.expandButton)}
            aria-label={t('episodeRow.expandButtonLabel')}
          >
            {children}
          </div>
        </Hidden>
        <Hidden smDown>
          {children}
        </Hidden>
      </div>
    );
  }, [classes.column, classes.expandButton, classes.summaryContainer, t]);

  let imageSrc;
  let imageId;

  if (odImageId) {
    imageId = odImageId;
  } else {
    imageSrc = getImageByType(images, ['hd720', 'thumbnailLarge', 'thumbnail']);
  }

  return (
    <div className={classes.root}>
      <div data-ui-name="episode-thumbnail" className={classes.column}>
        <OdLink
          name={route.name}
          params={route.params}
          className={classes.playLink}
          onClick={playVideoHandler}
          onKeyPress={onButtonKeyListener(playVideoHandler)}
          aria-label={label}
        >
          <Hidden smDown>
            <Image
              classes={{ image: classes.thumbnail }}
              alt={title}
              aria-label={title}
              src={imageSrc}
              imageId={imageId}
              ratio="391:220"
              srcSetWidths={[391, 782]}
              imageSizes={{ all: 391 }}
            />
            <PlayButton
              overlay
              classes={{
                playIcon: classes.playButton,
                overlay: classes.playButtonOverlay,
              }}
            />
            {progressData.percent > 0 ? (
              <VideoProgressBar
                transparent
                progressPercent={progressData.percent}
                position="bottom"
              />
            ) : null}
          </Hidden>
          <Hidden mdUp>
            <PlayButton size={48}/>
          </Hidden>
        </OdLink>
      </div>
      <SummaryContainer>
        <Typography
          variant="h2"
          component="h3"
          className={classes.title}
        >
          {`${episodeNumber ? `${episodeNumber}. ` : ''}${title}`}
        </Typography>
        <Hidden mdUp>
          <div
            className={classes.expandToggleButton}
          >
            <ExpandIcon
              className={`${classes.expandIcon} ${showDescription ? classes.expanded : ''}`}
              aria-hidden="true"
              aria-expanded={showDescription}
            />
          </div>
        </Hidden>
        <div
          className={classes.description}
          style={showDescription ? { display: 'block' } : {}}
        >
          <div>{description}</div>
          <div className={classes.metadata}>
            <Hidden mdUp>
              {classification && (
                <ClassificationBadge classification={classification}/>
              )}
              {consumerAdvices && (
                <Tooltip
                  title={consumerAdviceTexts ? consumerAdviceTexts.join(', ') : ''}
                  arrow
                  placement="top"
                >
                  <div className={classes.consumerAdvices}>
                    {consumerAdvicesString}
                  </div>
                </Tooltip>
              )}
            </Hidden>
          </div>
        </div>
        <div className={classes.metadata}>
          <Hidden smDown>
            {classification && (
              <ClassificationBadge classification={classification}/>
            )}
            {consumerAdvices && (
              <Tooltip
                title={consumerAdviceTexts ? consumerAdviceTexts.join(', ') : ''}
                arrow
                placement="top"
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.tooltipArrow,
                }}
              >
                <div className={classes.consumerAdvices}>
                  {consumerAdvicesString}
                </div>
              </Tooltip>
            )}
          </Hidden>
          {durationSeconds > 0 && (
            <time
              className={classes.duration}
              dateTime={duration.toISO()}
              aria-label={t('video.duration', {
                duration: durationSeconds,
                formatParams: {
                  duration: {
                    units: durationFormatUnits,
                  },
                },
              })}
            >
              <span>
                {t('video.duration', {
                  duration: durationSeconds,
                  formatParams: {
                    duration: {
                      ...durationShortFormatParams,
                      units: durationFormatUnits,
                    },
                  },
                })}
              </span>
            </time>
          )}
          {expiredDate && (
            <div className={classes.availability}>
              <ExpirationDate date={expiredDate}/>
            </div>
          )}
        </div>
      </SummaryContainer>
    </div>
  );
};

export default EpisodeRow;
