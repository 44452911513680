import { getVideoAsyncThunk } from '@@stores/VideoStore';
import OnDemand from '@@types/OnDemand';
import { SSRFunctionComponent } from '@@types/ssr';
import { logVideoNotMatching } from '@@utils/helpers';

import VideoPage from './VideoPage';

interface MoviePageProps {
  video: OnDemand.Video;
}

const MoviePage: SSRFunctionComponent<MoviePageProps, { id: string }> = (props) => {
  return <VideoPage {...props}/>;
};

MoviePage.getInitialProps = async ({ store, match: { params: { id } }, language }) => {
  const video = await store.dispatch(getVideoAsyncThunk(id, language));

  if (typeof window === 'undefined') {
    logVideoNotMatching(id, video, { component: 'MoviePage' });
  }

  return { video };
};

export default MoviePage;
