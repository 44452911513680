import { findIndex } from 'lodash';
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import useRecommendedItems from '@@src/hooks/useRecommendedItems';
import SeriesPageStructuredData from '@@src/utils/StructuredData/SeriesPageStructuredData';
import { getSeries } from '@@stores/SeriesStore';
import OnDemand from '@@types/OnDemand';
import { SSRFunctionComponent } from '@@types/ssr';
import StructuredData from '@@utils/StructuredData/StructuredData';

import SeriesSummary from '../components/Content/SeriesSummary';
import { NotFoundError } from '../components/Error/Error';
import { getResizedUrl } from '../components/Html/Image';
import SeasonTabbedView from '../components/List/SeasonTabbedView';
import DetailsPageLayout from '../components/PageLayouts/DetailsPageLayout';
import PageRows from '../components/PageRows/PageRows';
import AlternateLinks from '../components/Utils/AlternateLinks';
import HttpRedirect from '../components/Utils/HttpRedirect';
import UnfurlingMetaTags from '../components/Utils/UnfurlingMetaTags';
import { generateFullUrlFromLinkProps, generatePathFromLinkProps } from '../routes';
import { SeriesPageLayout } from '../services/SeriesService';
import DataLayer from '../utils/DataLayer';

const SeriesPage: SSRFunctionComponent<SeriesPageLayout, { seriesType: OnDemand.SeriesType; slug: string; seasonSlug?: string; }> = (props) => {
  const {
    series,
    staticContext,
    match: {
      params:
        {
          slug,
          seriesType,
          seasonSlug,
        },
    },
    onLoadSuccess = () => {},
    onLoadError = () => {},
  } = props;

  const { t, i18n: { language } } = useTranslation(['page', 'common']);
  const recommendedItems = useRecommendedItems(series?.id, language, '16:9');

  // scroll to top if slug changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  useEffect(() => {
    if (series) {
      DataLayer.updatePageAttributesForSeries(series);

      if (!series.expired) {
        onLoadSuccess(series.pageTitle);
      }
    }

    if (series === null || series?.expired) {
      onLoadError('404', 'Page Not Found');
    }
  }, [onLoadError, onLoadSuccess, series]);

  if (series === null || series?.expired) {
    // if static context available, set the status code to 404 for ssr
    if (staticContext) {
      staticContext.statusCode = 404;
    }

    return <NotFoundError/>;
  }

  let seasonTabItems = [];

  // series is still being loaded
  if (!series) {
    return null;
  }

  const unfurlingImageUrl = getResizedUrl(
    {
      imageId: series.ogImageId,
    },
    {
      width: 640,
      height: 360,
    },
  );

  seasonTabItems = series.seasons;

  // if we have extras, append it to the season tab items
  if (series.extras.length > 0) {
    seasonTabItems = seasonTabItems.concat([{
      id: 'extras',
      name: 'Extras',
      seasonNumber: null,
      seasonSlug: 'extras',
      episodes: series.extras.map((value) => {
        return {
          ...value,
          // extras from catalogue api don't currently return classification & consumer advices
          classification: null,
          consumerAdvices: [],
          type: 'Episode',
        };
      }),
    }]);
  }

  let selectedTabIndex = 0;

  if (seasonSlug) {
    selectedTabIndex = findIndex(seasonTabItems, { seasonSlug });
  }

  // seasonSlug was defined, but it's not valid, redirect to tv series page
  if (selectedTabIndex < 0) {
    return <HttpRedirect to={generatePathFromLinkProps({ name: 'series', params: { slug, seriesType } })}/>;
  }

  // we are not escaping the value to avoid double escaping by react component below
  let pageTitle = t('page:detailsPage.pageTitle', { pageTitle: series.pageTitle, interpolation: { escapeValue: false } });

  if (seasonSlug && (selectedTabIndex >= 0)) {
    pageTitle += `: ${seasonTabItems[selectedTabIndex].name}`;
  }

  // we are not escaping the value to avoid double escaping by react component below
  const description = t('page:detailsPage.pageDescription', { pageTitle: series.pageTitle, interpolation: { escapeValue: false } });
  const structuredData = SeriesPageStructuredData.generate(series, language);

  return (
    <div>
      <Helmet
        titleTemplate="%s | Stream free on SBS On Demand"
      >
        <title>{pageTitle}</title>
        <meta name="description" content={description}/>
      </Helmet>
      <AlternateLinks/>
      <UnfurlingMetaTags
        title={series.pageTitle}
        description={series.metaDescription}
        type="video.tv_show"
        imageUrl={unfurlingImageUrl}
        url={generateFullUrlFromLinkProps(series.route, language)}
      />
      <StructuredData>
        {JSON.stringify(structuredData)}
      </StructuredData>

      <DetailsPageLayout backgroundImageId={series.odImageId}>
        <SeriesSummary series={series}/>
        <SeasonTabbedView
          seriesSlug={series.slug}
          seriesType={series.seriesType}
          items={seasonTabItems}
          selectedTabIndex={selectedTabIndex}
        />
        <PageRows
          rows={
            [
              {
                id: `recommendation-shelf-${series.id}`,
                type: 'recommendation-shelf',
                catalogueId: series.id,
                name: 'You May Also Like',
                collection: {
                  id: `recommendation-shelf-${series.id}`,
                  title: 'You May Also Like',
                  layout: 'shelf',
                  displayType: '16:9',
                  route: null,
                  items: recommendedItems,
                },
              },
            ]
          }
        />
      </DetailsPageLayout>

    </div>
  );
};

SeriesPage.getInitialProps = async ({ store, match: { params: { seriesType, slug } }, language }) => {
  const series = await store.dispatch(getSeries(seriesType, slug, language));

  return {
    series,
    rows: [],
  };
};

SeriesPage.shouldGetInitialProps = (oldProps, newProps) => {
  return oldProps.match.params.slug !== newProps.match.params.slug;
};

// disable scroll to top
SeriesPage.disableScrollToTop = true;

export default SeriesPage;
