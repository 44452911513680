import { Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Announcement from '@@src/components/Content/Announcement';
import { nl2br } from '@@utils/helpers';

import OnDemand from '../../@types/OnDemand';
import useQuery from '../../hooks/useQuery';
import { generatePathFromLinkProps } from '../../routes';
import HttpRedirect from '../Utils/HttpRedirect';
import FeaturedVideo from '../Video/FeaturedVideo';
import ExpiredVideoPlayWidget from '../Video/VideoPlayWidget-Expired';
import ContentSummary, { BottomMetadataItem } from './ContentSummary';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    tileBody: {
      fontSize: '1.125rem',
      lineHeight: 1.56,
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
      marginBottom: theme.spacing(3),
      flex: 'none',
    },
    featuredVideo: {
      marginBottom: 32,
    },
  });
});

export interface SeriesSummaryProps {
  series: OnDemand.TvSeries;
}

const SeriesSummary: FunctionComponent<SeriesSummaryProps> = (props) => {
  const { series } = props;
  const classes = useStyles({});

  const { t } = useTranslation('common');
  const { action, autoplay } = useQuery(true);

  let seasonsAvailableString = null;

  if (series.numberOfSeasons) {
    seasonsAvailableString = (
      <span>
        {t('metadata.seasonsAvailable', { count: series.numberOfSeasons })}
      </span>
    );
  }

  const topMetadataList = [];
  if (series.numberOfSeasons) {
    topMetadataList.push({ id: 'numberOfSeason', value: seasonsAvailableString });
  }

  if (series.languages.length > 0) {
    topMetadataList.push({ id: 'languages', value: series.languages[0] });
  }

  const bottomMetadataList: BottomMetadataItem[] = [];
  if (series.languages.length > 1) {
    bottomMetadataList.push({ languages: { type: 'string', items: series.languages } });
  }

  if (series.featuredVideo) {
    const { video } = series.featuredVideo;

    if (action === 'play' || autoplay === '1') {
      const to = generatePathFromLinkProps({
        name: 'watch',
        params: {
          id: video.id,
        },
      });

      return <HttpRedirect to={to}/>;
    }
  }

  const subtitlesMetadata: BottomMetadataItem = {
    subtitles: {
      type: 'string',
      items: series.subtitles,
    },
  };
  const countriesMetadata: BottomMetadataItem = {
    countries: {
      type: 'string',
      items: series.countries,
    },
  };
  const directorsMetadata: BottomMetadataItem = {
    directors: {
      type: 'personCollection',
      personRole: 'Director',
      items: series.directors,
    },
  };
  const castMetadata: BottomMetadataItem = {
    cast: {
      type: 'personCollection',
      personRole: 'Cast member',
      items: series.cast,
    },
  };
  const consumerAdviceMetadata: BottomMetadataItem = {
    advice: {
      type: 'string',
      items: series.consumerAdviceTexts,
    },
  };

  return (
    <ContentSummary
      item={series}
      topMetadata={{
        list: topMetadataList,
        badges: {
          classification: series.classification,
          hasClosedCaption: series.hasClosedCaption,
          isHighDefinition: series.isHighDefinition,
          hasAudioDescription: series.hasAudioDescription,
        },
      }}
      bottomMetadata={[
        ...bottomMetadataList,
        subtitlesMetadata,
        countriesMetadata,
        directorsMetadata,
        castMetadata,
        consumerAdviceMetadata,
      ]}
      sponsorship={series.sponsorship}
    >
      <Typography gutterBottom variant="body2" className={classes.tileBody}>
        {nl2br(series.description)}
      </Typography>
      {
        series.announcement && <Announcement announcement={series.announcement}/>
      }
      {
        !series.expired && series.featuredVideo && (
          <FeaturedVideo
            label={series.featuredVideo.title}
            video={series.featuredVideo.video}
            classes={{ root: classes.featuredVideo }}
          />
        )
      }
      {
        series.expired && (
          <ExpiredVideoPlayWidget
            title={t('video.expired[TvSeries]')}
            classes={{ root: classes.featuredVideo }}
          />
        )
      }
    </ContentSummary>
  );
};

export default SeriesSummary;
