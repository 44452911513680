import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getResizedUrl } from '@@src/components/Html/Image';
import SeasonTabbedView from '@@src/components/List/SeasonTabbedView';
import DataLayer from '@@utils/DataLayer';
import * as StructuredData from '@@utils/StructuredData';

import { SSRFunctionComponent } from '../@types/ssr';
import SeriesSummary from '../components/Content/SeriesSummary';
import { NotFoundError } from '../components/Error/Error';
import DetailsPageLayout from '../components/PageLayouts/DetailsPageLayout';
import PageRows from '../components/PageRows/PageRows';
import AlternateLinks from '../components/Utils/AlternateLinks';
import HttpRedirect from '../components/Utils/HttpRedirect';
import UnfurlingMetaTags from '../components/Utils/UnfurlingMetaTags';
import useQuery from '../hooks/useQuery';
import { generateFullUrlFromLinkProps, generatePathFromLinkProps, OdLinkProps } from '../routes';
import { getSeriesPageLayoutBySlug, fetchRemainingSeasons, SeriesPageLayout } from '../services/SeriesService';

const ProgramPage: SSRFunctionComponent<SeriesPageLayout, { slug: string }> = (props) => {
  const {
    series,
    staticContext,
    onLoadSuccess = () => {},
    onLoadError = () => {},
    rows = [],
  } = props;
  const query = useQuery(true);
  const [seasons, setSeasons] = useState(series ? series.seasons : []);

  useEffect(() => {
    if (series) {
      DataLayer.updatePageAttributesForSeries(series);

      fetchRemainingSeasons(series.seasons).then((result) => {
        setSeasons(result);
      });

      onLoadSuccess(series.pageTitle);
    }

    if (series === null) {
      onLoadError('404', 'Page Not Found');
    }

    return (() => {
      DataLayer.resetPageAttribute();
    });
  }, [onLoadError, onLoadSuccess, series]);

  const { i18n: { language } } = useTranslation();
  const location = useLocation();

  if (series === null) {
    // if static context available, set the status code to 404 for ssr
    if (staticContext) {
      staticContext.statusCode = 404;
    }

    return <NotFoundError/>;
  }

  const programPathRegex = /^\/program\/.+/;

  const redirectExceptions = [
    'the-handmaids-tale-arabic',
    'the-handmaids-tale-simplified-chinese',
    'the-australian-wars-arabic',
    'the-australian-wars-korean',
    'the-australian-wars-traditional-chinese',
    'the-australian-wars-simplified-chinese',
    'the-australian-wars-vietnamese',
  ];

  if (series && series.route.name === 'series' && programPathRegex.test(location.pathname) && !redirectExceptions.includes(series.slug)) {
    const linkProps: OdLinkProps = {
      ...series.route,
      query,
    };
    return <HttpRedirect to={generatePathFromLinkProps(linkProps)}/>;
  }

  const unfurlingImageUrl = getResizedUrl(
    {
      imageId: series.ogImageId,
    },
    {
      width: 640,
      height: 360,
    },
  );

  return (
    <div>
      {
        series
        && (
          <>
            <Helmet>
              <title>{series.pageTitle}</title>
              <meta name="description" content={series.metaDescription}/>
              <script type="application/ld+json">
                {JSON.stringify(StructuredData.generateTvSeriesSchema(series, language))}
              </script>
              <link href={generateFullUrlFromLinkProps(series.route, language)} rel="canonical"/>
            </Helmet>
            <AlternateLinks/>
            <UnfurlingMetaTags
              title={series.pageTitle}
              description={series.metaDescription}
              type="video.tv_show"
              imageUrl={unfurlingImageUrl}
              url={generateFullUrlFromLinkProps(series.route, language)}
            />
            <DetailsPageLayout backgroundImage={series.images.thumbnailHd || series.images.thumbnailLarge || series.images.thumbnail}>
              <SeriesSummary series={series}/>
              <SeasonTabbedView items={seasons} seriesType={series.seriesType} seriesSlug={series.slug} renderTabAsLink={false}/>
              <PageRows key={series.id} rows={rows}/>
            </DetailsPageLayout>
          </>
        )
      }
    </div>
  );
};

ProgramPage.getInitialProps = ({ match: { params: { slug } } }) => {
  return getSeriesPageLayoutBySlug(slug)
    .then((data) => {
      return data;
    });
};

export default ProgramPage;
