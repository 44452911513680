import { SSRFunctionComponent } from '../@types/ssr';
import { NotFoundError } from '../components/Error/Error';
import HttpRedirect from '../components/Utils/HttpRedirect';
import { getSeriesSlugById } from '../services/SeriesService';

interface ProgramByIdProps {
  slug: string;
}

const ProgramPageById: SSRFunctionComponent<ProgramByIdProps, { id: string }> = (props) => {
  const { slug, staticContext } = props;

  if (slug === null) {
    if (staticContext) {
      staticContext.statusCode = 404;
    }

    return <NotFoundError/>;
  }

  return <HttpRedirect to={`/program/${slug}`}/>;
};

ProgramPageById.getInitialProps = ({ match: { params: { id } } }) => {
  return getSeriesSlugById(id)
    .then((data) => {
      return {
        slug: data,
      };
    });
};

export default ProgramPageById;
