import { generateFullUrlFromLinkProps, generateFullUrlFromPath } from '@@src/routes';
import SeriesStructuredData from '@@src/utils/StructuredData/SeriesStructuredData';
import OnDemand from '@@types/OnDemand';

export default class SeriesPageStructuredData {
  static generate(series: OnDemand.TvSeries, language: string) {
    return {
      '@context': 'https://schema.org/',
      '@type': 'WebPage',
      '@id': generateFullUrlFromLinkProps(series.route, language),

      isPartOf: {
        '@type': 'WebSite',
        name: 'SBS On Demand',
        url: generateFullUrlFromPath('/'),
      },

      mainEntity: SeriesStructuredData.generate(series, language),
    };
  }
}
