import { get } from 'lodash';

import OnDemand from '@@types/OnDemand';
import { V3_API_HOST } from '@@utils/constants';
import Logger from '@@utils/logger/Logger';

import ProgramLookupApi from '../apis/ProgramLookupApi';
import { RecommendationShelfRow, ShelfRow } from '../components/PageRows/PageRow';
import FeedTransformer, { transformToSlimEpisodeItem } from '../transformers/FeedTransformer';
import CatalogService from './CatalogService';
import { getPageRowItems } from './PageService';

export interface SeriesPageLayout {
  series: OnDemand.TvSeries;
  rows?: (ShelfRow | RecommendationShelfRow)[];
}

export async function fetchRemainingSeasons(partialSeasons) {
  const fullSeasons = await partialSeasons.reduce(async (seasons, season) => {
    const _seasons = await seasons;

    if (season.episodes.length === 0) {
      const episodes = (
        await CatalogService.getItems(
          season.feedUrl,
          transformToSlimEpisodeItem,
          false,
          1,
          400,
        )
      ).items;

      if (episodes.length) {
        const _season: OnDemand.SeriesItemSeason = {
          id: season.name,
          name: season.name,
          seasonNumber: season.seasonNumber,
          seasonSlug: season.seasonSlug,
          episodes,
        };

        _seasons.push(_season);
      }
    } else {
      _seasons.push(season);
    }

    return _seasons;
  }, Promise.resolve<OnDemand.SeriesItemSeason[]>([]));

  return fullSeasons;
}

/**
 * Fetch a series object from API and transform it to series item
 */
export function getSeriesPageLayoutBySlug(slug): Promise<SeriesPageLayout> {
  return ProgramLookupApi.fetchBySlug(slug)
    .then(async (result) => {
      const series = FeedTransformer.transformToSeriesItem(result.program);
      series.seasons = [];

      if (!series.expired) {
        if (result.seasons) {
          series.seasons = await result.seasons.reduce<Promise<OnDemand.SeriesItemSeason[]>>(async (seasons, season, index) => {
            const _seasons = await seasons;
            const seasonNumber = get(season, 'metadata.seasonNumber', null);

            // Only fetches episodes for the first season in the tab list
            if (index === 0) {
              // @todo paginate the episodes
              const episodes = (await CatalogService.getItems(season.feedUrl, transformToSlimEpisodeItem, false, 1, 400)).items;

              if (episodes.length) {
                const _season: OnDemand.SeriesItemSeason = {
                  id: season.name,
                  name: season.name,
                  seasonNumber,
                  episodes,
                };

                _seasons.push(_season);
              }
            } else {
              const _season: OnDemand.SeriesItemSeason = {
                id: season.name,
                name: season.name,
                seasonNumber,
                episodes: [],
                feedUrl: season.feedUrl,
              };
              _seasons.push(_season);
            }

            return _seasons;
          }, Promise.resolve<OnDemand.SeriesItemSeason[]>([]));
        }

        if (result.featuredVideo && result.featuredVideo.video) {
          series.featuredVideo = {
            title: result.featuredVideo.title,
            video: FeedTransformer.transformToFeaturedVideoItem(result.featuredVideo.video),
          };
        }
      }

      let rows = [];
      if (result.rows) {
        const pageRows: ShelfRow[] = [{
          name: 'More Like This',
          type: 'shelf',
          itemType: 'title-under',
          feedUrl: `${V3_API_HOST}/api/v3/video_recommendations/moreseries?id=${series.id}&context=odwebsite`,
          requireLogin: false,
        }];

        rows = await Promise.all(pageRows.map(async (pageRow) => {
          return {
            ...pageRow,
            ...await getPageRowItems(pageRow),
          };
        }));
      }

      return {
        series,
        rows,
      };
    })
    .catch((e) => {
      if (e.response && e.response.status === 400) {
        // Series is not found. Do not log as it will be logged elsewhere
      } else {
        Logger.error(new Error('Error in getSeriesPageLayoutBySlug'), {
          error: {
            message: e.message,
            status: e.response ? e.response.status : null,
            response: e.response ? e.response.data : null,
          },
        });
      }
      return {
        series: null,
        rows: null,
      };
    });
}

export function getSeriesSlugById(id: string): Promise<string> {
  return ProgramLookupApi.fetchById(id)
    .then(async (result) => {
      return get(result, 'program.slug', null);
    })
    .catch(() => {
      return null;
    });
}

export default {
  getSeriesPageLayoutBySlug,
};
