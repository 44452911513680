import { getVideoAsyncThunk } from '@@stores/VideoStore';
import OnDemand from '@@types/OnDemand';
import { SSRFunctionComponent } from '@@types/ssr';
import { logVideoNotMatching } from '@@utils/helpers';

import VideoPage from './VideoPage';

export interface EpisodePageProps {
  video: OnDemand.Video;
}

const EpisodePage: SSRFunctionComponent<EpisodePageProps, { id: string }> = (props) => {
  return <VideoPage {...props}/>;
};

EpisodePage.getInitialProps = async ({
  store,
  match: { params: { id } },
  language,
}) => {
  const video = await store.dispatch(getVideoAsyncThunk(id, language));

  if (typeof window === 'undefined') {
    logVideoNotMatching(id, video, { component: 'EpisodePage' });
  }

  return { video };
};

export default EpisodePage;
